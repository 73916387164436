<template>
    <div class="h-100 d-flex flex-column justify-content-center align-items-center" v-show="show">
        <b-spinner label="Spinning" class="text-primary"></b-spinner>
        <div class="mt-2" v-if="descriptionShow && description">
            {{ description }}
        </div>
    </div>
</template>
<script>
    import i18n from '@/plugins/i18n'

    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            descriptionShow: {
                type: Boolean,
                default: false
            },
            description: {
                type: String,
                default: () => {
                    return i18n.t('loading')
                }
            }
        }
    }
</script>
